import React, {
  createContext,
  useContext,
  useState,
  useRef, useEffect,
} from 'react';
import { fetchAudios } from '@app/services/AudioService';
import { rtfEndpoint } from '@app/services/HttpService';

const AudioPlayerContext = createContext(undefined);

const transformData = list => list.reduce((res, item) => {
  res.push({
    ...item,
    src: `${rtfEndpoint}/attachments/${item.src}`,
    thumbnail: `${rtfEndpoint}/attachments/${item.thumbnail}`,
  });
  return res;
}, []);

export const AudioPlayerProvider = ({
  children,
}) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [trackIndex, setTrackIndex] = useState(0);
  const [currentTrack, setCurrentTrack] = useState({ });
  const [timeProgress, setTimeProgress] = useState(0);
  const [duration, setDuration] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);

  const audioRef = useRef(null);
  const progressBarRef = useRef(null);

  const [tracks, setTracks] = useState([]);

  useEffect(() => {
    // eslint-disable-next-line promise/catch-or-return
    fetchAudios()
      // eslint-disable-next-line promise/always-return
      .then((data) => {
        setTracks(transformData(data));
      });
  }, []);

  useEffect(() => {
    // eslint-disable-next-line no-unused-expressions
    tracks && setCurrentTrack(tracks[trackIndex]);
  }, [trackIndex, tracks]);

  useEffect(() => {
    setIsLoaded(currentTrack && !!currentTrack?.title);
  }, [currentTrack]);

  const contextValue = {
    isLoaded,
    tracks,
    currentTrack,
    setCurrentTrack,
    audioRef,
    progressBarRef,
    timeProgress,
    setTimeProgress,
    duration,
    setDuration,
    setTrackIndex,
    isPlaying,
    setIsPlaying,
  };

  return (
    <AudioPlayerContext.Provider value={contextValue}>
      {isLoaded && children}
    </AudioPlayerContext.Provider>
  );
};

export const useAudioPlayerContext = () => {
  const context = useContext(AudioPlayerContext);

  if (context === undefined) {
    throw new Error(
      'useAudioPlayerContext must be used within an AudioPlayerProvider',
    );
  }

  return context;
};
