import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import './style.scss';

class RussianFairyTalesButton extends Component {
  render() {
    return (
      <div className="pst-rft">
        <button className="pst-rft__btn" onClick={() => this.props.toggle()}>Послушать русские народные сказки</button>
      </div>
    );
  }
}

RussianFairyTalesButton.propTypes = {
  toggle: PropTypes.func,
};

export default withTranslation()(RussianFairyTalesButton);
