import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Swipeable } from 'react-swipeable';
import Joyride, { EVENTS, ACTIONS } from 'react-joyride';
import CookiesService, { BRIEF, SHOW_PAYMENT, PAYMENT_PERIOD } from '@app/services/CookiesService';
import { withTranslation } from 'react-i18next';
import LockScreenHandler from '@app/components/NEW_ui/LockScreenHandler';
import ProblemQuiz from '@app/components/NEW_ui/ProblemQuiz';
import isRussianLanguage from '@helpers/isRussianLanguage';

import SplitLayout from '@app/components/NEW_ui/SplitLayout/index';
// import isShowLockScreenAfterLogin from '@helpers/isShowLockScreenAfterLogin';

import Feedback from '@app/components/NEW_ui/Feedback';
import { makePayment, hideLockScreen, showLockScreen, hideQuiz, showQuiz, saveQuizAnswer } from '@app/pages/Viewer/redux/actions';
import checkActiveOrder from '@helpers/checkActiveOrder';
import isIos from '@helpers/isIos';
import isEnglishLanguage from '@app/helpers/isEnglishLanguage';

import RightPanel from './components/RightPanel/index';
import LeftPanel from './components/LeftPanel/index';
import PochemushaChat from '../../components/NEW_ui/PochemushaChat/index';
import DeleteAccountPopup from '../../components/NEW_ui/DeleteAccountPopup';
import { FEEDBACK_QUIZ, PROBLEMS_QUIZ } from '../../store/constants/quizTypes';
import FeedbackQuiz from '../../components/NEW_ui/FeedbackQuiz';
import isShowProblemQuiz from '../../helpers/isShowProblemQuiz';
import isShowFeedbackQuiz from '../../helpers/isShowFeedbackQuiz';
import RussianFairyTales from '../../components/NEW_ui/RussianFairyTales';

let LAST_ACTIVE_PATH; // lol
const DEFAULT_ACTIVE_PATH = 'topics';
const ROUTER_TO_TAB = {
  topics: 'tab_1',
  'my-presentations': 'tab_2',
};

class MainDashboard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      defaultActivePath: DEFAULT_ACTIVE_PATH,
      lastActivePath: undefined,
      showTour: false,
      showPochemusha: false,
      showRussianFairyTales: false,
    };
  }

  componentDidMount() {
    // eslint-disable-next-line no-unused-vars
    const { order, makePay, showLock, user } = this.props;
    const shownBrief = CookiesService.getCookie(BRIEF);
    const showPayment = CookiesService.getCookie(SHOW_PAYMENT);
    const paymentPeriod = Number(CookiesService.getCookie(PAYMENT_PERIOD));
    const isBought = order !== null && checkActiveOrder(order);

    if (showPayment && !isBought) {
      const period = paymentPeriod || 1;
      makePay(period, isIos(window));

      CookiesService.deleteCookie(SHOW_PAYMENT, { domain: 'myskazka.com' });
      CookiesService.deleteCookie(PAYMENT_PERIOD, { domain: 'myskazka.com' });
    } else if (showPayment && isBought) {
      CookiesService.deleteCookie(SHOW_PAYMENT, { domain: 'myskazka.com' });
      CookiesService.deleteCookie(PAYMENT_PERIOD, { domain: 'myskazka.com' });
    }

    if (isShowProblemQuiz(user)) {
      this.props.showQuiz(PROBLEMS_QUIZ);
    }

    if (isShowFeedbackQuiz(user)) {
      this.props.showQuiz(FEEDBACK_QUIZ);
    }

    // if (isShowLockScreenAfterLogin(user)) {
    //   showLock();
    // }

    if (!shownBrief) {
      document.body.style.overflow = 'hidden';
      setTimeout(() => this.setState({ showTour: true }), 500);
    }
  }

  changeTab = (tab) => {
    const { history } = this.props;
    const path = Object.keys(ROUTER_TO_TAB).find(key => ROUTER_TO_TAB[String(key)] === tab) || DEFAULT_ACTIVE_PATH;

    history.push(path);
    LAST_ACTIVE_PATH = path;
  };

  handleJoyrideCallback = (data) => {
    const { action, type } = data;

    if ([EVENTS.TOUR_END].includes(type) || [ACTIONS.CLOSE].includes(action)) {
      document.body.style.overflow = 'auto';
      this.setState({ showTour: false });
      CookiesService.setCookie(BRIEF);
    }
  };

  steps = [
    {
      target: '.pst-presentationFilterTags--top',
      content: this.props.t('dashboardTour.step_1'),
      placement: 'bottom',
      placementBeacon: 'bottom',
      spotlightPadding: 10,
      disableBeacon: true,
    },
    {
      target: '.pst-presentationFilterTags--bottom',
      content: this.props.t('dashboardTour.step_2'),
      placement: 'top',
      placementBeacon: 'top',
      spotlightPadding: 10,
    },
    {
      target: '.pst-newPresentationList',
      content: this.props.t('dashboardTour.step_3'),
      position: 'top',
      placementBeacon: 'top',
      spotlightPadding: 0,
      offset: 20,
    },
    {
      target: '.pst-switcher__piece--second',
      content: this.props.t('dashboardTour.step_4'),
      placement: 'bottom',
      placementBeacon: 'bottom',
      spotlightPadding: 10,
      disableBeacon: true,
    },
  ];

  locale = {
    back: this.props.t('mainTour.back'),
    last: this.props.t('mainTour.last'),
    next: this.props.t('mainTour.next'),
    skip: this.props.t('mainTour.skip'),
  };

  paymentHandler = (period) => {
    const { hideLock, makePay } = this.props;
    makePay(period, isIos(window));
    hideLock();
  };

  handlePochemushaChat = () => {
    this.setState({
      ...this.state,
      showPochemusha: !this.state.showPochemusha,
    });
  };

  handleRussianFairyTales = () => {
    this.setState({
      ...this.state,
      showRussianFairyTales: !this.state.showRussianFairyTales,
    });
  }

  render() {
    const { user, quiz, price, history, showLockScreenPopup, showDeleteAccountPopup, hideLock, match: { params: { page } } } = this.props;
    const showProblemQuiz = PROBLEMS_QUIZ === quiz;
    const showFeedbackQuiz = FEEDBACK_QUIZ === quiz && isRussianLanguage();
    const currentTab = ROUTER_TO_TAB[String(page)];

    if (history.location.pathname === '/' || currentTab === undefined) return <Redirect to={LAST_ACTIVE_PATH || DEFAULT_ACTIVE_PATH} />;

    if (page !== LAST_ACTIVE_PATH) LAST_ACTIVE_PATH = page;

    const swipeableConfig = {
      delta: 50,
      trackTouch: true,
    };
    if (showProblemQuiz) {
      return <ProblemQuiz
        user={user}
        fixed
        onClose={this.props.hideQuiz}
        onClick={this.props.saveQuizAnswer}
      />;
    }
    if (showFeedbackQuiz) {
      return <FeedbackQuiz
        user={user}
        fixed
        onClose={this.props.hideQuiz}
        onClick={this.props.saveQuizAnswer}
      />;
    }

    return (
      <Swipeable
        onSwipedLeft={() => this.changeTab('tab_2')}
        onSwipedRight={() => this.changeTab('tab_1')}
        {...swipeableConfig}
      >
        { !showLockScreenPopup && <Joyride
          callback={this.handleJoyrideCallback}
          run={this.state.showTour}
          steps={this.steps}
          disableScrolling
          showSkipButton
          showProgress
          continuous
          disableOverlayClose
          locale={this.locale}
          styles={{
            options: {
              zIndex: 10,
              primaryColor: '#7421d9',
            },
            spotlight: {
              borderRadius: '20px',
            },
          }}
        />}
        <SplitLayout
          mod='dashboard'
          isLeftSectionHideTablet
          left={<LeftPanel />}
          right={<RightPanel
            showTab={currentTab}
            changeTab={this.changeTab}
            history={history}
            togglePochemushaChat={this.handlePochemushaChat}
            toggleRussianFairyTales={this.handleRussianFairyTales}
          />}
        />
        <Feedback mod='dashboard' />
        { !isEnglishLanguage() && showLockScreenPopup && <LockScreenHandler
          fixed
          onClose={hideLock}
          onClick={this.paymentHandler}
          promo={price.promo}
          priceMonth={price.monthAmount}
          discountMonth={price.monthDiscount}
          priceYear={price.yearAmount}
          discountYear={price.yearDiscount}
        />}
        { this.state.showPochemusha && <PochemushaChat toggleChat={this.handlePochemushaChat}/> }
        { this.state.showRussianFairyTales && <RussianFairyTales toggle={this.handleRussianFairyTales}/> }
        {showDeleteAccountPopup && <DeleteAccountPopup/>}

      </Swipeable>
    );
  }
}

const mapStateToProps = ({ subscriptions, auth, sertificate, quizzes }) => ({
  order: subscriptions.order,
  price: subscriptions.price,
  showLockScreenPopup: subscriptions.showLockScreen,
  showDeleteAccountPopup: sertificate.showDeleteAccountPopup,
  user: auth.user,
  quiz: quizzes.quiz,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  makePay: makePayment,
  showLock: showLockScreen,
  hideLock: hideLockScreen,
  showQuiz,
  hideQuiz,
  saveQuizAnswer,
}, dispatch);

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(MainDashboard));
