/* eslint-disable no-shadow */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import isRussianLanguage from '@app/helpers/isRussianLanguage';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

import Switcher from '@app/components/NEW_ui/Input/Switcher';
import BaseLoader from '@app/components/NEW_ui/BaseLoader';
import LogoPanel from '@app/components/NEW_ui/LogoPanel';

import NewPresentation from '../NewPresentation/index';
import MyPresentation from '../MyPresentation/index';

import {
  fetchTopic,
  addSelectedTagFilter,
  removeSelectedTagFilter,
  fetchMyPresentation,
  deletePastylla,
  createPresByTemplate,
} from './redux/actions';
import getVisibleTodos from './redux/select/filterDashboardList';

import { allTagsCollector } from '../../helpers';

import './style.scss';
import Why from '../Why/index';
import RussianFairyTalesButton from '../RussianFairyTalesButton';

class RightPanel extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);

    this.state = {
      tags: [],
      scroll: 0,
      showPreloader: false,
      progress: 0,
    };
  }

  async componentDidMount() {
    this._isMounted = true;

    await this.props.fetchTopic();

    const { dashboardList } = this.props;
    const tags = allTagsCollector(dashboardList);
    if (this._isMounted) this.setState({ tags, scroll: window.pageYOffset });

    await this.props.fetchMyPresentation();

    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    this._isMounted = false;
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll = () => {
    const scroll = window.pageYOffset;
    this.setState({ scroll });
  }

  loadCallback = (type, growth) => {
    const { progress } = this.state;
    const newProgress = progress + growth;

    if (type === 'img') this.setState({ progress: newProgress });
    if (type === 'designs') this.setState({ progress: 20 });
    if (type === 'created') this.setState({ progress: 100 });
  }

  createPresentationByTemplate = (topic) => {
    const { createPresByTemplate, history } = this.props;
    const { structure } = topic;
    const howManyImages = structure.length;
    const _growth = 65 / howManyImages;

    const loader = (type) => {
      this.loadCallback(type, _growth);
    };

    this.setState({ showPreloader: true });
    document.body.style.overflow = 'hidden';

    // create new presentation by tempalte and redirect to design...
    createPresByTemplate(topic, loader)
      .then((res) => {
        setTimeout(() => {
          history.push(`/${res.data._id}/design`);
          document.body.style.overflow = '';
        }, 1100);
        return res;
      })
      // eslint-disable-next-line no-console
      .catch(err => console.error(err));
  };

  handleChangeTab = (value) => {
    const { changeTab } = this.props;

    if (!value) changeTab('tab_1');
    else if (value) changeTab('tab_2');
  }

  render() {
    // eslint-disable-next-line no-shadow
    const {
      showTab,
      changeTab,
      dashboardList,
      addSelectedTagFilter,
      removeSelectedTagFilter,
      selectedTags,
      myPresentation,
      deletePastylla,
      t,
    } = this.props;
    const { tags, scroll, showPreloader, progress } = this.state;

    return (
      <div className='pst-rightPanel'>
        <LogoPanel
          mod='dashboard'
          fixedScroll={270}
          showFixedMenu={scroll >= 270}
          tags={tags}
          changeTab={changeTab}
          showTab={showTab}
          addTag={addSelectedTagFilter}
          removeTag={removeSelectedTagFilter}
          showAvatar
        />
        { isRussianLanguage() && <RussianFairyTalesButton toggle={this.props.toggleRussianFairyTales}/> }
        { isRussianLanguage() && <Why toggleChat={this.props.togglePochemushaChat}/> }

        <div className='pst-rightPanel__content'>
          <div className={`pst-rightPanel__tabsPanel ${showTab === 'tab_2' ? 'pst-rightPanel__tabsPanel--tab2' : ''}`}>
            <div className="pst-rightPanel__switcher">
              <Switcher
                initValue={showTab === 'tab_2'}
                labels={{
                  first: t('dashboard.tab_1'),
                  second: t('dashboard.tab_2'),
                }}
                onClick={this.handleChangeTab}
              />
            </div>
          </div>

          <div className='pst-rightPanel__tabs'>
            {showTab === 'tab_1' && (
              <NewPresentation
                tags={tags}
                dashboardList={dashboardList}
                addSelectedTagFilter={addSelectedTagFilter}
                removeSelectedTagFilter={removeSelectedTagFilter}
                selectedTags={selectedTags}
                handleSelectedPresentation={topic => this.createPresentationByTemplate(topic)}
              />
            )}

            {showTab === 'tab_2' && (
              <MyPresentation myPresentation={myPresentation} deleteMethod={deletePastylla} />
            )}
          </div>

          {showPreloader && <div className='pst-rightPanel__preloader'>
            <BaseLoader title={t('slideEditor.awaitJustified')} progress={progress} />
          </div>}
        </div>
      </div>
    );
  }
}

RightPanel.defaultProps = {
  showTab: 'tab_1',
};

RightPanel.propTypes = {
  showTab: PropTypes.string,
  changeTab: PropTypes.func,
  history: PropTypes.object,
};

const mapStateToProps = state => ({
  myPresentation: state.dashboard.myPresentation,
  dashboardList: getVisibleTodos(state),
  selectedTags: state.dashboard.filters.tags,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  fetchTopic,
  addSelectedTagFilter,
  removeSelectedTagFilter,
  fetchMyPresentation,
  deletePastylla,
  createPresByTemplate,
}, dispatch);

export default withTranslation()(withRouter(connect(mapStateToProps, mapDispatchToProps)(RightPanel)));
